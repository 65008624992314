jQuery(document).ready(function($){
	$('.slick-row-two_row').slick({
	  dots: false,
	  arrows: true,
          nextArrow: '<button type="button" class="slick-arrow next-arrow"></button>',
          prevArrow: '<button type="button" class="slick-arrow prev-arrow"></button>',
	  infinite: true,
	  speed: 300,
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  autoplay: false,
	  rows: 2,
	  slidesPerRow: 6,
	  responsive: [
	    {
	      breakpoint: 992,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1,
	       	slidesPerRow: 4,
	        infinite: true,
	      }
	    },
	    {
	      breakpoint: 768,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1,
	       	slidesPerRow: 3,
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1,
 	       	slidesPerRow: 2,
	      }
	    }
	  ]
	});

	$('.slick-merk.slick-row-one_row').slick({
	  dots: false,
	  arrows: true,
          nextArrow: '<button type="button" class="slick-arrow next-arrow"></button>',
          prevArrow: '<button type="button" class="slick-arrow prev-arrow"></button>',
	  infinite: true,
	  speed: 300,
	  slidesToShow: 6,
	  slidesToScroll: 6,
	  autoplay: false,
	  responsive: [
	    {
	      breakpoint: 992,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1,
	        infinite: true,
	      }
	    },
	    {
	      breakpoint: 768,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1,
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1,
	      }
	    }
	  ]
	});

});
