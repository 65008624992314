jQuery(document).ready(function($){
	//Add compact class
	document.mainMenu = $('body');
    $(window).scroll(function () {

        if ($(window).scrollTop() > 30 ) {
            document.mainMenu.addClass('compact');
        } 
        else {
            document.mainMenu.removeClass('compact');
        }
    });
    
    $('.navi .fa-search').click(function(){
        $('.search-header').toggle();
        $('.compact .header').toggleClass('s-header');
    });
    
    $('header .fa-times').click(function(){
       $('.search-header').toggle(); 
       $('.compact .header').removeClass('s-header');
    });
        
});
 